<template lang="">
  <div>
    <base-header class="pb-7 pt-md-6 bg-gradient-dark"></base-header>
    <div class="order-xl-1">
      <div class="card">
        <div class="card-header">
          <b-row align-v="center" slot="header">
            <b-col cols="8">
              <h2 class="mb-0">
                <i class="fa fa-pen"></i> แก้ไขข้อมูลการชำระเงิน
              </h2>
            </b-col>
          </b-row>
        </div>
        <div class="card-body bg-white">
          <validation-observer v-slot="{ handleSubmit }" ref="formValidator">
            <b-form @submit.prevent="handleSubmit(saveHandler)">
              <form-payment :item="item" :merges="merges" :action="`edit`" ref="formPayment" :flagEdit="flagEdit" :images="images":imageDeletes="imageDeletes"/>
              <div class="float-right d-flex">
                <span><router-link :to="'/payment'" class="nav-link text-underline">กลับ</router-link></span>
                <!-- <b-button variant="secondary" @click="onBackHandler()"><i class="fa fa-arrow-left"></i> กลับ</b-button> -->
                <b-button type="submit" variant="primary" class="style_btn" v-if="flagEdit && permission.actionData.flag_update"><i class="fa fa-check"></i> บันทึก</b-button>
              </div>
            </b-form>
          </validation-observer>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Config from "../../../util/config";
import DateUtils from "../../../util/dateUtils";
import StringUtils from "../../../util/StringUtils";
import formPayment from "./form.payment";
import Swal from 'sweetalert2/dist/sweetalert2';
export default {
  name: "payment-view-page-payment-edit",
  data() {
    return {
      permission: {
        appSlug: "payment",
        actionData: {}
      },
      flagEdit: true,
      item: {},
      merges: [],
      images: [],
      imageDeletes: []
    };
  },
  methods: {
    async saveHandler() {
      // console.log("file this.item.upload", this.item.upload);

      const file = this.item.upload;
      delete this.item.upload;
      this.item.upload_encode = "";
      let params = JSON.parse(JSON.stringify(this.item));
      params.payment = DateUtils.dateFormat(params.payment,"YYYY-MM-DD HH:mm:ss");
      params.merges = JSON.stringify(this.merges);
      this.SpinnerService.showLoader();
      const result = await this.HttpServices.putData(`/ab_payment/${this.item.id}`,params);
      // console.log("edit params: ", params);
      if (result && result.status.code == "200") {
        const s3Auth = await this.HttpServices.authenS3();
        const medias = [];

        // console.log("file", file);
        if (file && file.length > 0) {
          if (s3Auth._info) {
            const accessToken = s3Auth._info.secret.accessToken;
            for (const f of file) {
              if (f instanceof File) {
                let params = new FormData();
                params.append("file", f);
                params.append("title", f.name);
                params.append("file_category", "order-payment-slip");
                const resultMedia = await this.HttpServices.postFormDataS3(
                  params,
                  accessToken
                );
                if (resultMedia.media && resultMedia.media.length > 0) {
                  const media = resultMedia.media[0];
                  const mediaUpload = {
                    id: media.id,
                    original_name: media.original_name,
                    mime_type: media.mime_type,
                    size: media.size,
                    url: media.url
                  };

                  params = new FormData();
                  const thumbFile = await StringUtils.resizeBlobToFile({
                    file: f,
                    maxSize: 200
                  });
                  params.append("file", thumbFile);
                  params.append("title", thumbFile.name);
                  params.append("file_category", "order-payment-slip");
                  let resultThumb = await this.HttpServices.postFormDataS3(
                    params,
                    accessToken
                  );
                  if (resultThumb.media && resultThumb.media.length > 0) {
                    const mediaThumb = resultThumb.media[0];
                    mediaUpload.id_t = mediaThumb.id;
                    mediaUpload.original_name_t = mediaThumb.original_name;
                    mediaUpload.mime_type_t = mediaThumb.mime_type;
                    mediaUpload.size_t = mediaThumb.size;
                    mediaUpload.url_t = mediaThumb.url;
                  }
                  medias.push(mediaUpload);
                }
              } else {
                const mediaUpload = {
                  id: f.id,
                  original_name: f.original_name,
                  mime_type: f.mime_type,
                  size: f.size,
                  url: f.url
                };
                if (f.id_t) {
                  mediaUpload.id_t = f.id_t;
                  mediaUpload.original_name_t = f.original_name_t;
                  mediaUpload.mime_type_t = f.mime_type_t;
                  mediaUpload.size_t = f.size_t;
                  mediaUpload.url_t = f.url_t;
                }
                medias.push(mediaUpload);
              }
            }
          }
        }
        // console.log("medias: ", medias);
        if (medias.length > 0) {
          let params = JSON.parse(JSON.stringify(this.item));
          params.payment = DateUtils.dateFormat(
            params.payment,
            "YYYY-MM-DD HH:mm:ss"
          );


          params.merges = JSON.stringify(this.merges);
          params.upload = JSON.stringify(medias);
          // params.file = JSON.stringify(medias);
          // params.upload_encode = JSON.stringify(medias);
          params.media = JSON.stringify(medias);

          // let params = JSON.parse(JSON.stringify(this.item));
          // console.log("update photos: ", this.item, "params: ", params);
          const result = await this.HttpServices.putData(
            `/ab_payment/upload/${this.item.id}`,
            { upload: JSON.stringify(medias) }
          );
          // console.log("result upload: ", result);

          // const result = await this.HttpServices.putData(
          //   `/ab_payment/${this.item.id}`,
          //   params
          // );
          // console.log("result: ", result, params);
        }
        if (s3Auth._info) {
          const accessToken = s3Auth._info.secret.accessToken;
          if (this.imageDeletes && this.imageDeletes.length > 0) {
            for (const v of this.imageDeletes) {
              await this.HttpServices.deleteDataS3(v, accessToken);
            }
          }
        }
        Swal.fire({
              title: "",
              html: `บันทึกสำเร็จ <br><small>*เนื่องจากมีการปรับปรุงระบบใหม่ โปรดกดปุ่มกลับ เพื่อกลับหน้าแรก</small>`,
              icon: "success",
              showCancelButton: false,
              "confirmButtonClass": "btn btn-primary",
              "confirmButtonText": 'ตกลง'
          }).then((result) => {
            window.location.reload();
          });
      } else {
        this.AlertUtils.alert("warning", result.status.message);
      }
      this.SpinnerService.hideLoader();
    },
    onBackHandler() {
      this.$router.push("/payment");
    },

    async getItem() {
      const result = await this.HttpServices.getData(`/ab_payment/${this.id}`);
      if (result && result.status.code == "200") {
        const data = result.data;
        this.item = data.item;
        this.merges = data.merges || [];
        this.item.payment = DateUtils.toDateFormat(this.item.payment, null);
        const result2 = await this.HttpServices.getData(`/ab_order/getCustomerById/${data.item.customer_id}`);
        if (result2.data) {
          this.item.customer_name = result2.data.company_name || result2.data.name;
          await this.$refs.formPayment.init();
        }

        if (this.item.upload_encode) {
          this.item.upload_encode = `${this.item.upload_encode}`;
        }

        this.item.imageDisplays = this.item.upload
          ? JSON.parse(this.item.upload) || this.item.upload
          : [];

        //  console.log('this.item.imageDisplays',this.item.imageDisplays)
        this.item.upload = this.item.upload
          ? JSON.parse(this.item.upload) || this.item.upload
          : [];
        this.images = this.item.upload
          ? JSON.parse(this.item.upload) || this.item.upload
          : [];


        this.item.deletes = [];

      } else {
        this.AlertUtils.alertCallback(
          "warning",
          `ไม่พบข้อมูลการชำระเงิน`,
          () => {
            this.$router.push("/payment");
          }
        );
      }
    }
  },
  components: {
    formPayment
  },
  async mounted() {
    console.log(this.permission.appSlug)
    await this.PermissionServices.viewPermission(this.permission.appSlug,this.$router);
    this.permission.actionData = await this.PermissionServices.getPermission(this.permission.appSlug);

    this.id = await this.SessionStorageUtils.getSession("page-payment");
    await this.getItem();
    this.$refs.formPayment.onTotalHandler();
    
  }
};
</script>
